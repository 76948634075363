/* eslint eqeqeq: 0 */

import { formatCNPJToString, validarCpfCnpj } from "../../../../../utils/helpers/StringHelper";

export const validate = (values) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  let errors = {
    // Geral
    documento: !values.documento ? 'Preenchimento obrigatório' : !validarCpfCnpj(formatCNPJToString(values.documento)) ? 'Documento inválido' : null,
    nome: !values.nome ? "Preenchimento obrigatório" : null,
    email: !values.email ? "Email obrigatório" : !emailPattern.test(values.email) ? "Email inválido" : null,
  };

  return errors;
};
