/* eslint eqeqeq: 0 */

import axios from "axios";
import { change } from 'redux-form';

import { OMNI_USUARIO_ROUTE_PATH, NOVO_ROUTE_PATH, DETALHAR_ROUTE_PATH, EDITAR_ROUTE_PATH, LISTA_ROUTE_PATH } from "../../components/common/routers/constantesRoutes";
import history from '../../components/common/routers/history';

import { showConfirmDialog } from '../common/modal/ConfirmDialogAction';
import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import {
    sereializeFieldsOmniUsuarioFilter,
    serializeFieldsOmniUsuarioForm,
} from "../../components/business/omniusuario/mappings/OmniUsuarioMap";
import { downloadExcelFile } from "../../utils/helpers/FileHelper";

export const OMNI_USUARIO_FETCHED = 'OMNI_USUARIO_FETCHED';
export const LOAD_FORM_OMNI_USUARIO = "LOAD_FORM_OMNI_USUARIO";
export const OMNI_USUARIO_SHOW_DELETE_DIALOG = 'OMNI_USUARIO_SHOW_DELETE_DIALOG';
export const CLEAN_LIST_CONTENT = "CLEAN_LIST_CONTENT";
export const SAVE_FORM_OMNI_USUARIO_ERRORS = "SAVE_FORM_OMNI_USUARIO_ERRORS";
export const INITIAL_STATE_OMNI_USUARIO = "INITIAL_STATE_OMNI_USUARIO";

const OMNI_USUARIO_API =  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OMNI_USUARIO_API;

export function limpaDependenteOnChange(dependente, value = null) {
    return (dispatch) => {
      dispatch([change("omniUsuarioForm", dependente, value)]);
    };
}

export function getOmniUsuarios(filters, page = 1) {
    const filter = sereializeFieldsOmniUsuarioFilter(filters, page);
    return (dispatch) => {
        return axios
            .post(`${OMNI_USUARIO_API}buscar`, { ...filter })
            .then(result =>
                dispatch([
                    {
                        type: OMNI_USUARIO_FETCHED,
                        payload: result
                    }
                ])
            );
    };
}

export function novoUsuarioOmni() {
    return (dispatch) => {
      return dispatch([
        history.push(`/${OMNI_USUARIO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`),
      ]);
    };
  }

export function exportOmniUsuarioExcel(filters, page = 1) {
    const params = sereializeFieldsOmniUsuarioFilter(filters, page);
  
    return (dispatch) => {
        return axios
            .post(`${OMNI_USUARIO_API}exportar`, params)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                ])
  
                if (result.content) {
                  const { content } = result;
  
                  dispatch([
                    content ? downloadExcelFile(content?.arquivo, `${content?.nomeArquivo}.${content?.extensaoArquivo}`) : null,
                  ])
                }
            }); 
  
    }
  }

export function mudaDependenteOnChange(form = "omniUsuarioForm", dependente, value = null) {
    return (dispatch) => {
      dispatch([change(form, dependente, value)]);
    };
  }

export function cleanResult() {
    return (dispatch) => {
        return dispatch([
            { type: CLEAN_LIST_CONTENT },
        ]);
    }
}

export function cleanSearch() {
    return (dispatch) => {
        return dispatch([
            {
                type: INITIAL_STATE_OMNI_USUARIO,
                payload: null
            }
        ]);
    }
}

export function editOmniUsuario(omniUsuario) {

    return (dispatch) => {
        dispatch([
            {
                type: SAVE_FORM_OMNI_USUARIO_ERRORS,
                payload: null
            },
        ])
        return axios
            .put(OMNI_USUARIO_API, omniUsuario)
            .then(result =>
                dispatch([
                    showOmniUsuarioList(),
                    showAllMessages(result.messages),
                ])
            ).catch((err) => {
                if (err.response && err.response.data?.messages && err.response.data?.messages?.hasError) {
                    document.getElementById('topContent').scrollTo({ top: 0, behavior: 'smooth' });
                    dispatch([
                        {
                            type: SAVE_FORM_OMNI_USUARIO_ERRORS,
                            payload: err.response.data?.messages
                        },
                    ])
                }
            })
    }
}

export function removeOmniUsuario(idOmniUsuario) {
    return (dispatch) => {
        return axios
            .delete(OMNI_USUARIO_API + idOmniUsuario)
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    showOmniUsuarioDeleteDialog(false, 0),
                ])
            ).catch(error => {
                dispatch(showOmniUsuarioDeleteDialog(false, 0))
            });
    }
}

export function showOmniUsuarioDeleteDialog(showDialog, idOmniUsuario, message) {
    return (dispatch) => {
        return dispatch([
            {
                type: OMNI_USUARIO_SHOW_DELETE_DIALOG,
                payload: { showDialog, idOmniUsuario, message }
            },
        ])
    }
}

export function loadFormOmniUsuario(id) {
    return dispatch => {
        return axios.get(`${OMNI_USUARIO_API}tela/${id || ''}`)
            .then(result => {
                dispatch([
                    {
                        type: LOAD_FORM_OMNI_USUARIO,
                        payload: result.content
                    },
                    serializeFieldsOmniUsuarioForm(result.content)
                ])
            });
    };
}

export function showOmniUsuarioForm(idOmniUsuario, editAction) {
    let routeAction = NOVO_ROUTE_PATH;

    if (idOmniUsuario !== null) {
        routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
        routeAction += `/${idOmniUsuario}`;
    }

    return dispatch => {
        return dispatch([
            history.push(`/${OMNI_USUARIO_ROUTE_PATH}/${routeAction}`),
            { type: '' }
        ]);
    };
}

export function showOmniUsuarioList() {
    return dispatch => {
        return dispatch([
            showConfirmDialog(false),
            history.push(`/${OMNI_USUARIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ]);
    };
}

export function saveOmniUsuario(omniUsuario) {
    return (dispatch) => {
        dispatch([
            {
                type: SAVE_FORM_OMNI_USUARIO_ERRORS,
                payload: null
            },
        ])
        return axios
            .post(OMNI_USUARIO_API, omniUsuario)
            .then(result =>
                dispatch([
                    showOmniUsuarioList(),
                    showAllMessages(result.messages)
                ])
            ).catch((err) => {
                if (err.response && err.response.data?.messages && err.response.data?.messages?.hasError) {
                    document.getElementById('topContent').scrollTo({ top: 0, behavior: 'smooth' });
                    dispatch([
                        {
                            type: SAVE_FORM_OMNI_USUARIO_ERRORS,
                            payload: err.response.data?.messages
                        },
                    ])
                }
            })
    }
}
