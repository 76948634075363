
import { initialize } from 'redux-form';
import { formatStrToCnpjOrCpf, removeFormat } from '../../../../utils/helpers/StringHelper';
import { extractFromObj } from '../../../../utils/helpers/ObjectHelper';

export function serializeFieldsOmniUsuarioForm(omniUsuario) {
    return initialize("omniUsuarioForm", {...omniUsuario});
}

export function deserializeFieldsOmniUsuarioForm(omniUsuario) {
    function ajustaObjetos(obj) {
        return obj.map(tipo => {
            const emailsObj = tipo.tipoServicoPrestadoEmpresaEmails.map(email => ({ email }));
            return {...tipo, tipoServicoPrestadoEmpresaEmails: emailsObj};
        })
    }

    const contas = omniUsuario?.empresaOmniUsuario?.contas?.map((ct) => ({...ct, idBanco: null, idBancoExterno: extractFromObj(ct?.idBancoExterno)})) || [];

    var result = {
        id: omniUsuario.empresaOmniUsuario.id || 0,
        idEmissor: omniUsuario.empresaOmniUsuario.idEmissor || 0,
        cnpj: omniUsuario.empresaOmniUsuario.cnpj ? removeFormat(omniUsuario.empresaOmniUsuario.cnpj) : null,
        razaoSocial: omniUsuario.empresaOmniUsuario.razaoSocial || null,
        nomeSimplificado: omniUsuario.empresaOmniUsuario.nomeSimplificado || null,
        sigla: omniUsuario.empresaOmniUsuario.sigla || null,
        codigo: omniUsuario.empresaOmniUsuario.codigo || null,
        tipoServicoPrestadoEmpresaList: ajustaObjetos(omniUsuario.empresaOmniUsuario.tiposServicosPrestadosSelecionados),
        personalidade: omniUsuario.personalidade,
        complemento: omniUsuario.complemento,
        enderecos: [{...omniUsuario.endereco}],
        idCnae: extractFromObj(omniUsuario?.empresaOmniUsuario?.idCnae),
        contas,
        statusAprovacao: omniUsuario?.empresaOmniUsuario?.statusAprovacao,
        enviarEmail: omniUsuario?.enviarEmail,
        confirmarRegistro: omniUsuario?.confirmarRegistro,
        //
        emailPessoa: omniUsuario.empresaOmniUsuario?.emailPessoa,
        telefone: omniUsuario.empresaOmniUsuario?.telefone,
        nomeFantasia: omniUsuario.empresaOmniUsuario?.nomeFantasia,
        perfis: Array.from(omniUsuario.empresaOmniUsuario?.perfis).map((perfil) => ({ perfil })),
        rg: omniUsuario.empresaOmniUsuario?.rg,
        emitirNotaFiscal: omniUsuario.empresaOmniUsuario?.emitirNotaFiscal,
        realizarPagamento: omniUsuario.empresaOmniUsuario?.realizarPagamento,
        estadoCivil: omniUsuario.empresaOmniUsuario?.estadoCivil,
    }
    return result;
}



export function sereializeFieldsOmniUsuarioFilter(filters, page = 1) {
    return {
        ...filters,
        pagingConfig: { pageIndex: page, pageSize: 10 }
    }
}

export function sereializeFieldsCnpjValidation(idOmniUsuario, cnpj) {

    return {
        Id: idOmniUsuario,
        cnpj: cnpj ? removeFormat(cnpj) : "",
    }
}

function serializeServicosPrestadosSelecionados(selecionados, servicos) {
  const res = Array(servicos.length).fill(null);

  selecionados.forEach(item => {
    const posicao = servicos.findIndex(servico => servico.descricao === item.descricao);
    if (posicao !== -1) res[posicao] = true;
  });

  return res;
}

function sortServicosPrestadosList(servicoA, servicoB) {
    var result = 0;
    if (servicoA.idTipoServicoPrestado < servicoB.idTipoServicoPrestado) {
        result = -1
    }
    else if (servicoA.idTipoServicoPrestado > servicoB.idTipoServicoPrestado) {
        result = 1
    }
    return result

}
