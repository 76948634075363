import React from "react";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";

let ModalListInfo = (props) => {
  const { close, open, list, title = 'Título' } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal">{ title }</p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-2">
            {list?.length ? (
              list?.map((item, i) => (
                <div key={i} className="mt-3 fw-400">
                  <p className="mb-0 p-color">{item.value}</p>
                  <p className="mb-0 p-color f-12 txt-grey">{item.label}</p>
                </div>
              ))
            ) : (<p className="mt-3 mb-0 f-16 text-center">Nada encontrado</p>)}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default connect(null, null)(ModalListInfo);
