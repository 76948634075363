import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import Button from "../../../../common/button/Button";
import MaskedFormatInput from "../../../../common/input/MaskedFormatInput";
import {
  CNPJ,
  customFormatCnpjCpf,
} from "../../../../../utils/helpers/StringHelper";
import Input from "../../../../common/input/Input";

import {
  getOmniUsuarios,
  mudaDependenteOnChange,
  novoUsuarioOmni,
} from "../../../../../actions/omniusuario/OmniUsuarioAction";
import {
  CREATE,
  OMNI_USUARIOS,
} from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";

let OmniUsuarioListFilter = (props) => {
  const {
    getOmniUsuarios,
    cnpj,
    nomeSimplificado,
    pesquisou,
    mudaDependenteOnChange,
    authorizationFunction,
    novoUsuarioOmni,
    documento,
    nome,
    email,
  } = props;

  const data = {
    documento,
    nome,
    email,
  };

  React.useEffect(() => {
    getOmniUsuarios(data);
  }, []);

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Usuários (Omni)"} />
        <FormContent classes="shadow-custom rounded-12 bg-white px-3 py-2">
          <div className="row">
            <div className="col-lg">
              <Field
                maxLength="18"
                label="CPF/CNPJ"
                name="documento"
                inputId="documento"
                className={"filter-input"}
                component={MaskedFormatInput}
                placeholder={CNPJ.placeholder}
                formatMask={customFormatCnpjCpf}
              />
            </div>
            <div className="col-lg">
              <Field
                component={Input}
                className={"filter-input"}
                inputId="nome"
                name="nome"
                type="text"
                label="Nome Usuário"
              />
            </div>
            <div className="col-lg">
              <Field
                component={Input}
                inputId="email"
                name="email"
                type="email"
                label="E-mail Vinculado"
              />
            </div>
          </div>

          <div className="row w-100 align-items-start mx-0">
            <Button
              name={"Buscar"}
              action={() => getOmniUsuarios(data)}
              nameClass="text-capitalize f-14 lh-1 mt-1px"
              activeClassName="col-12 col-lg-2 pl-0 pr-0 pr-lg-4"
              classes="p-bg-imp rounded btn-search h-38 mt-2 mt-lg-1 w-100 d-flex justify-content-center px-5"
            />

            <Button
              icon="plus-circle"
              name={"Criar Usuário"}
              action={() => {
                authorizationFunction(
                  {
                    idAcao: CREATE,
                    idFuncionalidade: OMNI_USUARIOS,
                  },
                  () => novoUsuarioOmni()
                );
              }}
              activeClassName="col-12 col-lg-2 pl-0 pr-0"
              nameClass="mt-2px text-capitalize f-14"
              classes="darkblue-bg-imp rounded btn-search h-38 mt-0 mt-lg-1 mr-1 w-100 d-flex justify-content-center align-items-center"
            />
          </div>
        </FormContent>
      </Form>
    </div>
  );
};
OmniUsuarioListFilter = reduxForm({
  form: "omniUsuarioFilterForm",
})(OmniUsuarioListFilter);
const selector = formValueSelector("omniUsuarioFilterForm");

const mapStateToProps = (state) => ({
  documento: selector(state, "documento"),
  nome: selector(state, "nome"),
  email: selector(state, "email"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOmniUsuarios,
      mudaDependenteOnChange,
      authorizationFunction,
      novoUsuarioOmni,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OmniUsuarioListFilter);
