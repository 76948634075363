/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  reduxForm,
  formValueSelector,
  Field,
  arrayInsert,
  arrayRemove,
} from "redux-form";
import MaskedFormatInput from "../../../../common/input/MaskedFormatInput";
import Input from "../../../../common/input/Input";
import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import {
  CNPJ,
  CPF,
  customFormatCnpjCpf,
} from "../../../../../utils/helpers/StringHelper";
import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";
import { validate } from "../validation/OmniUsuarioFormValidation";
import {
  showOmniUsuarioList,
  limpaDependenteOnChange,
} from "../../../../../actions/omniusuario/OmniUsuarioAction";
import Button from "../../../../common/button/Button";
import FixedFloaterErrors from "../../../../common/floats/FixedFloaterErrors";
import { withRouter } from "react-router-dom";
import { msgError } from "../../../../../utils/helpers/OthersHelpers";

import { withStyles } from "@material-ui/core/styles";
import GenericPickerInput from "../../../../common/input/GenericPickerInput";
import { getSugestaoPatrimonio } from "../../../../../actions/patrimonio/PatrimonioAction";
import ModalAction from "../../../../common/modal/ModalAction";

const styles = (theme) => ({
  parent: {
    width: "100%",
    marginTop: "0.5%",
    backgroundColor: "transparent",
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: "#630A37",
    "&:disabled": {
      color: "#D0D0D0",
    },
  },
  buttons: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "1rem !important",
  },
  stepper: {
    backgroundColor: "transparent",
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: "flex",
      justifyContent: "center",
    },
    "& [class*='MuiTypography-root']": {
      width: "max-content",
      padding: "0 10px",
      borderRadius: "20px",
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: "#B8B8B8",
        color: "#121212c9",
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: "#FFC107",
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: "#83BF74",
        color: "#ffffff",
        "&.revisao": {
          backgroundColor: "#cc0000",
        },
      },
    },
  },
});

const OmniUsuarioForm = (props) => {
  const {
    title,
    handleSubmit,
    showOmniUsuarioList,
    floatButtonVisible,
    showConfirmDialog,
    showCancelConfirmDialog,
    messages,
    invalid,
    patrimoniosList,
    getSugestaoPatrimonio,
    documento,
    arrayInsert,
    arrayRemove,
    patrimonioSeparadoList,
  } = props;

  return (
    <Form>
      <FormHeader title={title} col="col-lg" offSet={20} />
      <FormContent handleSubmit={handleSubmit}>
        <div className="row">
          {messages?.hasError && (
            <FixedFloaterErrors
              messages={messages}
              title="Status do Cadastro"
            />
          )}

          <div className="col-12">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <span className="h3 page-head__title">Geral</span>
              <div className="row">
                <div className="col-lg-4">
                  <Field
                    required
                    inputId="cnpj"
                    maxLength={18}
                    name="documento"
                    className="filter-input"
                    component={MaskedFormatInput}
                    formatMask={customFormatCnpjCpf}
                    placeholder={`${CPF.placeholder} ou ${CNPJ.placeholder}`}
                    label={
                      documento?.length == 18
                        ? "CNPJ"
                        : documento?.length == 14
                        ? "CPF"
                        : "CPF/CNPJ"
                    }
                  />
                </div>

                <div className="col-lg-4">
                  <Field
                    required
                    inputId="nome"
                    name="nome"
                    component={Input}
                    label="Nome do Participante"
                  />
                </div>

                <div className="col-lg-4">
                  <Field
                    required
                    inputId="email"
                    name="email"
                    type="email"
                    component={Input}
                    label="E-mail"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <span className="h3 page-head__title">Patrimônio Separado</span>
              <div className="col-12 mt-1p mt-2 px-0">
                <div className="d-flex flex-column">
                  <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                    Patrimônio Separado
                  </label>
                  <GenericPickerInput
                    loadData={getSugestaoPatrimonio}
                    options={patrimoniosList}
                    activeClassName="inputPgmt"
                    formName="patrimonioForm"
                    fieldName="patrimonio"
                    isMulti
                    placeholder="Pesquisar operação ex.:CRI.1.SEC.2"
                    loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                    noOptionsMessage="Nenhum registro encontrado"
                    value={[]}
                    selectedOptionForm={(e) => {
                    !patrimonioSeparadoList.some(v => v.key === e[0].key) 
                      ? arrayInsert("omniUsuarioForm", "patrimonioSeparadoList", patrimonioSeparadoList?.length, ...e) 
                      : msgError("Patrimônio já adicionado a este usuário");
                    }}
                  />
                </div>

                <div className="mt-3 d-flex flex-row flex-wrap w-100">
                  {patrimonioSeparadoList.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="mb-2 mr-2 badge-user-omni rounded-pill d-flex flex-row align-items-center justify-content-center flex-nowrap"
                      >
                        <span className="lh-normal text-nowrap">{item.value}</span>
                        <svg
                          role="button"
                          onClick={() => arrayRemove("omniUsuarioForm", "patrimonioSeparadoList", i)}
                          xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"
                        >
                          <path d="M7.06 5.235L6 6.295L4.935 5.235L4.23 5.94L5.295 7L4.235 8.06L4.94 8.765L6 7.705L7.06 8.765L7.765 8.06L6.705 7L7.765 5.94L7.06 5.235ZM7.75 2L7.25 1.5H4.75L4.25 2H2.5V3H9.5V2H7.75ZM3 9.5C3 10.05 3.45 10.5 4 10.5H8C8.55 10.5 9 10.05 9 9.5V3.5H3V9.5ZM4 4.5H8V9.5H4V4.5Z" fill="#DC3545"/>
                        </svg>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 px-0">
          <div className="d-flex flex-row justify-content-around align-items-center col-12 mb-4 p-0">
            <Button
              name={`Cancelar ${floatButtonVisible ? "Registro" : ""}`}
              classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() =>
                floatButtonVisible
                  ? showConfirmDialog(true)
                  : showOmniUsuarioList()
              }
            />

            <Button
              type="submit"
              name="Salvar Participante"
              classes="scs-bg-dark-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() =>
                invalid &&
                msgError(
                  "Alguns erros foram encontrados. Por favor, revise atentamente todos os campos."
                )
              }
            />
          </div>
        </div>
      </FormContent>

      <ModalAction
        action={() => showOmniUsuarioList()}
        open={showCancelConfirmDialog}
        title="Aviso"
        message="Os dados do formulário serão perdidos"
        close={() => showConfirmDialog(false)}
      />
    </Form>
  );
};

const selector = formValueSelector("omniUsuarioForm");

const mapStateToProps = (state) => ({
  omniUsuario: state.omniUsuarioReducer.omniUsuarioFormTypes.omniUsuario || [],

  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  messages: state.omniUsuarioReducer.messages || [],
  patrimoniosList: state.patrimonioReducer.patrimoniosOptions,

  patrimonioSeparadoList: selector(state, "patrimonioSeparadoList") || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showOmniUsuarioList,
      showConfirmDialog,
      limpaDependenteOnChange,
      arrayInsert,
      arrayRemove,
      getSugestaoPatrimonio,
    },
    dispatch
  );

const ConnectedOmniUsuarioForm = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(OmniUsuarioForm))
);
export default reduxForm({ form: "omniUsuarioForm", validate })(
  ConnectedOmniUsuarioForm
);
