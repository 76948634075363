import {
    OMNI_USUARIO_FETCHED,
    OMNI_USUARIO_SHOW_DELETE_DIALOG,
    LOAD_FORM_OMNI_USUARIO,
    CLEAN_LIST_CONTENT,
    SAVE_FORM_OMNI_USUARIO_ERRORS,
    INITIAL_STATE_OMNI_USUARIO,
  } from "../../actions/omniusuario/OmniUsuarioAction";

const INITIAL_STATE = {
    omniUsuarios: {
      lastPage: 0,
      totalCount: 0,
      hasItems: false,
      pageSize: 0,
      pageIndex: 0,
      items: [],
    },
    filters: null,
    showOmniUsuarioDeleteDialog: {
      showDialog: false,
      idOmniUsuario: 0,
      message: ""
    },
    omniUsuarioFormTypes: {},
    messages: null, 
    fornecedores: []
}

export default function(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
      case INITIAL_STATE_OMNI_USUARIO: {
        return INITIAL_STATE
      }
      case OMNI_USUARIO_FETCHED:
        return {
          ...state,
            omniUsuarios: action.payload.content, 
            messages: action.payload.messages,
        }
      case LOAD_FORM_OMNI_USUARIO:
        return {
          ...state,
          omniUsuarioFormTypes: action.payload
        }
      case SAVE_FORM_OMNI_USUARIO_ERRORS:
        return {
          ...state,
          messages: action.payload
        }
      case OMNI_USUARIO_SHOW_DELETE_DIALOG:
          return {
              ...state,
              showOmniUsuarioDeleteDialog: action.payload
          }
      case CLEAN_LIST_CONTENT:
          return {
                ...state,
                omniUsuarios: {
                  lastPage: 0,
                  totalCount: 0,
                  hasItems: false,
                  pageSize: 0,
                  pageIndex: 0,
                  items: [],
                }
            }
        default: 
          return state; 
    }
}