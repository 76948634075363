/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customFormatCnpjCpf } from "../../../../../../utils/helpers/StringHelper";
import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import { OMNI_USUARIOS } from "../../../../../../utils/actionsAndFeaturesAuth";
import TableActionWithRouteAuthorization from "../../../../../common/table/TableActionWithRouteAuthorization";
import { showOmniUsuarioForm } from "../../../../../../actions/omniusuario/OmniUsuarioAction";

const PatrimonioFormUsuarios = (props) => {
  const { readOnly, list = [], showOmniUsuarioForm } = props;

  return (
    <div className="column" id="ptr-orig">
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mt-4">
        <span className="h3 page-head__title">Acessos Omni</span>
        <Table
          withoutHeader
          classes="border-0 shadow-none col-12 my-0 pd-10 bg-white"
        >
          <TableHeader>
            <tr>
              <th className={`text-center fw-700`}>CPF/CNPJ</th>
              <th className={`text-center fw-700`}>Nome do Usuário</th>
              <th className={`text-center fw-700`}>E-mail</th>
              {!readOnly && (
                <th className={`text-center fw-700`}></th>
              )}
            </tr>
          </TableHeader>
          <TableContent>
            {list?.length > 0 ? (
              list.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className={`text-center fw-400`}>
                      {item.documento
                        ? customFormatCnpjCpf(item.documento)
                        : "--"}
                    </td>
                    <td className={`text-center fw-400`}>
                      {item.nome || "--"}
                    </td>
                    <td className={`text-center fw-400`}>
                      {item.email || "--"}
                    </td>
                    {!readOnly && (
                      <TableActionWithRouteAuthorization
                        space="2"
                        feature={OMNI_USUARIOS}
                        updateAction={() => {
                          showOmniUsuarioForm(item.id, true);
                        }}
                      />
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={12}
                  align="center"
                  className={`text-center fw-400`}
                >
                  Não há usuários
                </td>
              </tr>
            )}
          </TableContent>
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showOmniUsuarioForm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatrimonioFormUsuarios);
