import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import OmniUsuarioForm from "./form/OmniUsuarioForm";
import {
  saveOmniUsuario,
  loadFormOmniUsuario,
} from "../../../../actions/omniusuario/OmniUsuarioAction";
import { formValueSelector } from "redux-form";

class OmniUsuarioIncludePage extends Component {
  componentDidMount() {

  }

  render() {
    const { saveOmniUsuario } = this.props;
    return (
      <OmniUsuarioForm
        title={"Criar - Usuário Omni"}
        onSubmit={saveOmniUsuario}
        readOnly={false}
        floatButtonVisible={true}
      />
    );
  }
}

const selector = formValueSelector("prestadorFilterForm");
const mapStateToProps = (state) => ({
  
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      saveOmniUsuario,
      loadFormOmniUsuario,
    },
    dispatch
  );
export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(OmniUsuarioIncludePage)
);
