import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import TableEmptyData from "../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";

import {
  getOmniUsuarios,
  showOmniUsuarioDeleteDialog,
  showOmniUsuarioForm,
  removeOmniUsuario,
  cleanSearch,
  exportOmniUsuarioExcel,
} from "../../../../../actions/omniusuario/OmniUsuarioAction";
import TableActionWithRouteAuthorization from "../../../../common/table/TableActionWithRouteAuthorization";
import {
  DELETE,
  FORNECEDORES_PRESTADORA,
  CREATE,
  OMNI_USUARIOS,
} from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import If from "../../../../common/If/If";
import ModalAction from "../../../../common/modal/ModalAction";
import ModalListInfo from "../../../../common/modal/ModalListInfo";

const OmniUsuarioList = (props) => {
  const {
    getOmniUsuarios,
    showOmniUsuarioDeleteDialog,
    showDeleteDialog,
    removeOmniUsuario,
    omniUsuarioData,
    messages,
    cnpj,
    nomeSimplificado,
    showOmniUsuarioForm,
    authorizationFunction,
    pesquisou,
    cleanSearch,
    idStatus,
    exportOmniUsuarioExcel,
  } = props;

  const filter = {
    cnpj,
    nomeSimplificado,
    pesquisou,
    idStatus,
    page: omniUsuarioData.pageIndex > 0 ? omniUsuarioData.pageIndex : 1,
  };

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">CPF/CNPJ</th>
      <th className="text-center fw-700 text-nowrap">NOME PARTICIPANTE</th>
      <th className="text-center fw-700 text-nowrap">E-MAIL</th>
      <th className="text-center fw-700 text-nowrap">PATRIMÔNIO SEPARADO</th>
      <th className="text-center fw-700 text-nowrap"></th>
    </tr>
  );

  const emptyData = messages && messages.infos && messages.infos[0];

  const [patrimonios, setPatrimonios] = React.useState(null);

  const tableContent =
    omniUsuarioData && omniUsuarioData.hasItems ? (
      omniUsuarioData.items.map((item, index) => (
        <tr key={index}>
          <td className="text-center text-nowrap fw-400">
            {item.documento}
          </td>
          <td className="text-center text-nowrap fw-400">
            {item.nome}
          </td>
          <td className="text-center text-nowrap fw-400">
            {item.email}
          </td>
          <td className="text-center text-nowrap fw-400">
            <svg role="button" onClick={() => setPatrimonios(item.patrimonioSeparadoList)} xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 13 10" fill="none">
              <path d="M0.173828 9.5C0.173828 9.36739 0.226507 9.24021 0.320275 9.14645C0.414043 9.05268 0.54122 9 0.673828 9H11.6738C11.8064 9 11.9336 9.05268 12.0274 9.14645C12.1212 9.24021 12.1738 9.36739 12.1738 9.5C12.1738 9.63261 12.1212 9.75979 12.0274 9.85355C11.9336 9.94732 11.8064 10 11.6738 10H0.673828C0.54122 10 0.414043 9.94732 0.320275 9.85355C0.226507 9.75979 0.173828 9.63261 0.173828 9.5ZM0.173828 6.5C0.173828 6.36739 0.226507 6.24021 0.320275 6.14645C0.414043 6.05268 0.54122 6 0.673828 6H11.6738C11.8064 6 11.9336 6.05268 12.0274 6.14645C12.1212 6.24021 12.1738 6.36739 12.1738 6.5C12.1738 6.63261 12.1212 6.75979 12.0274 6.85355C11.9336 6.94732 11.8064 7 11.6738 7H0.673828C0.54122 7 0.414043 6.94732 0.320275 6.85355C0.226507 6.75979 0.173828 6.63261 0.173828 6.5ZM0.173828 3.5C0.173828 3.36739 0.226507 3.24021 0.320275 3.14645C0.414043 3.05268 0.54122 3 0.673828 3H11.6738C11.8064 3 11.9336 3.05268 12.0274 3.14645C12.1212 3.24021 12.1738 3.36739 12.1738 3.5C12.1738 3.63261 12.1212 3.75979 12.0274 3.85355C11.9336 3.94732 11.8064 4 11.6738 4H0.673828C0.54122 4 0.414043 3.94732 0.320275 3.85355C0.226507 3.75979 0.173828 3.63261 0.173828 3.5ZM0.173828 0.5C0.173828 0.367392 0.226507 0.240215 0.320275 0.146447C0.414043 0.0526785 0.54122 0 0.673828 0H11.6738C11.8064 0 11.9336 0.0526785 12.0274 0.146447C12.1212 0.240215 12.1738 0.367392 12.1738 0.5C12.1738 0.632608 12.1212 0.759785 12.0274 0.853553C11.9336 0.947321 11.8064 1 11.6738 1H0.673828C0.54122 1 0.414043 0.947321 0.320275 0.853553C0.226507 0.759785 0.173828 0.632608 0.173828 0.5Z" fill="#630A37"/>
            </svg>
          </td> 
          <TableActionWithRouteAuthorization
            space="2"
            feature={OMNI_USUARIOS}
            updateAction={() => {
              cleanSearch();
              showOmniUsuarioForm(item.id, true);
            }}
            remove={() => showOmniUsuarioDeleteDialog(true, item.id)}
          />
        </tr>
      ))
    ) : (
      <TableEmptyData message={emptyData} />
    );

  return (
    <div className="w-100 px-4 mx-1">
      <If test={omniUsuarioData.totalCount > 0}>
        <Table
          withoutHeader
          files={[
            { label: 'Exportar para Excel', function: () => exportOmniUsuarioExcel(filter) }
          ]}        
          classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
          totalItems={omniUsuarioData.totalCount}
          pagination={
            <PaginationRB
              pageNumber={omniUsuarioData.pageIndex - 1}
              itemsPerPage={omniUsuarioData.pageSize}
              totalItems={omniUsuarioData.totalCount}
              filter={{ cnpj, nomeSimplificado, idStatus }}
              fetchFunction={getOmniUsuarios}
              pageRange={5}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>

      <ModalListInfo
        open={Boolean(patrimonios)}
        title="Patrimônios Vinculadas"
        close={() => setPatrimonios(null)}
        list={patrimonios}
      />

      <ModalAction
        action={() =>
          authorizationFunction(
            { idAcao: DELETE, idFuncionalidade: OMNI_USUARIOS },
            async ()  => {
              await removeOmniUsuario(showDeleteDialog.idOmniUsuario)
              getOmniUsuarios()
            }
          )
        }
        open={showDeleteDialog.showDialog}
        title="Deletar usuário"
        close={() => showOmniUsuarioDeleteDialog(false)}
      />
    </div>
  );
};

const selector = formValueSelector("prestadorFilterForm");
const mapStateToProps = (state) => ({
  cnpj: selector(state, "cnpj"),
  nomeSimplificado: selector(state, "nomeSimplificado"),
  idStatus: selector(state, "idStatus"),
  pesquisou: selector(state, "pesquisou"),
  showDeleteDialog: state.omniUsuarioReducer.showOmniUsuarioDeleteDialog,
  omniUsuarioData: state.omniUsuarioReducer.omniUsuarios,
  messages: state.omniUsuarioReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getOmniUsuarios,
      removeOmniUsuario,
      showOmniUsuarioDeleteDialog,
      showOmniUsuarioForm,
      authorizationFunction,
      cleanSearch,
      exportOmniUsuarioExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(OmniUsuarioList);
