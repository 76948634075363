import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  OMNI_USUARIO_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import OmniUsuarioPage from "../../omniusuario/pages/OmniUsuarioPage";
import OmniUsuarioIncludePage from "../../omniusuario/pages/OmniUsuarioIncludePage";
import OmniUsuarioUpdatePage from "../../omniusuario/pages/OmniUsuarioUpdatePage";

export default () => (
  <Switch>
    <Route
      exact={true}
      path={`/${OMNI_USUARIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`}
      component={OmniUsuarioPage}
    />
    <Route
      exact={true}
      path={`/${OMNI_USUARIO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idOmniUsuario`}
      component={OmniUsuarioUpdatePage}
    />
    <Route
      exact={true}
      path={`/${OMNI_USUARIO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`}
      component={OmniUsuarioIncludePage}
    />
  </Switch>
);
