import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContainerPage from "../../../common/container/ContainerPage"
import OmniUsuarioListFilter from "./form/OmniUsuarioListFilter"
import OmniUsuarioList from './list/OmniUsuarioList'

import { cleanResult } from '../../../../actions/omniusuario/OmniUsuarioAction';

class OmniUsuarioPage extends Component {
    componentWillUpdate(){
        this.props.cleanResult();
    }

    render() {
        return (
            <ContainerPage>
                <OmniUsuarioListFilter/>
                <OmniUsuarioList />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    cleanResult
}, dispatch);


export default connect(null, mapDispatchToProps)(OmniUsuarioPage);
