import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import OmniUsuarioForm from "./form/OmniUsuarioForm";
import {
  loadFormOmniUsuario,
  editOmniUsuario,
} from "../../../../actions/omniusuario/OmniUsuarioAction";
import { formValueSelector } from "redux-form";

class OmniUsuarioUpdatePage extends Component {
  componentDidMount() {
    let idOmniUsuario = this.props.match.params.idOmniUsuario;
    this.props.loadFormOmniUsuario(idOmniUsuario);
  }

  render() {
    const { editOmniUsuario } = this.props;
  

    return (
      <OmniUsuarioForm
        title={"Editar - Usuário Omni"}
        onSubmit={editOmniUsuario}
        readOnly={false}
        floatButtonVisible={true}
      />
    );
  }
}

const selector = formValueSelector("prestadorFilterForm");
const mapStateToProps = (state) => ({
  
});
const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      editOmniUsuario,
      loadFormOmniUsuario,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(OmniUsuarioUpdatePage)
);
