export const READ = process.env.REACT_APP_ACTION_READ;
export const CREATE = process.env.REACT_APP_ACTION_CREATE;
export const UPDATE = process.env.REACT_APP_ACTION_UPDATE;
export const DELETE = process.env.REACT_APP_ACTION_DELETE;
export const GENERATE = process.env.REACT_APP_ACTION_GENERATE;

export const OPERACAO_PASSIVO = process.env.REACT_APP_FEATURE_OPERACAO_PASSIVO;
export const OPERACAO_ATIVO = process.env.REACT_APP_FEATURE_OPERACAO_ATIVO;
export const OPERACAO_DESPESAS = process.env.REACT_APP_FEATURE_OPERACAO_DESPESAS;
export const OPERACAO_RECEITAS = process.env.REACT_APP_FEATURE_OPERACAO_RECEITAS;
export const OPERACAO_COMPLEMENTO = process.env.REACT_APP_FEATURE_OPERACAO_COMPLEMENTO;
export const ATIVO = process.env.REACT_APP_FEATURE_ATIVO;
export const PEDIDOS = process.env.REACT_APP_FEATURE_PEDIDOS;
export const APROVACAO = process.env.REACT_APP_FEATURE_APROVACAO;
export const APROVADORES = process.env.REACT_APP_FEATURE_APROVADORES;
export const PATRIMONIO = process.env.REACT_APP_FEATURE_PATRIMONIO;
export const PAGAMENTO = process.env.REACT_APP_FEATURE_PAGAMENTO;
export const MONITORAMENTO = process.env.REACT_APP_FEATURE_MONITORAMENTO;
export const DIARIO_DE_BORDO = process.env.REACT_APP_FEATURE_DIARIO_DE_BORDO;
export const MEMORIA_DE_CALCULO = process.env.REACT_APP_FEATURE_MEMORIA_DE_CALCULO;
export const CALCULADORA = process.env.REACT_APP_FEATURE_CALCULADORA;
export const PRECO = process.env.REACT_APP_FEATURE_PRECO;
export const FORNECEDORES_PRESTADORA = process.env.REACT_APP_FEATURE_FORNECEDORES_PRESTADORA;
export const INDICES = process.env.REACT_APP_FEATURE_INDICES;
export const OPCAO = process.env.REACT_APP_FEATURE_OPCAO;
export const AUDIT = process.env.REACT_APP_FEATURE_AUDIT;
export const LOG = process.env.REACT_APP_FEATURE_LOG;
export const ERRO = process.env.REACT_APP_FEATURE_ERRO;
export const DOMINIO = process.env.REACT_APP_FEATURE_DOMINIO;
export const OMNI_USUARIOS = process.env.REACT_APP_FEATURE_OMNI_USUARIO;
export const RELATORIOS_GERENCIAIS = process.env.REACT_APP_FEATURE_RELATORIOS_GERENCIAIS;
export const RELATORIOS_ANALITICOS = process.env.REACT_APP_FEATURE_RELATORIOS_ANALITICOS;
export const RELATORIOS_REGULATORIOS = process.env.REACT_APP_FEATURE_RELATORIOS_REGULATORIOS;
export const JOB = process.env.REACT_APP_FEATURE_JOB;
export const USUARIOS = process.env.REACT_APP_FEATURE_USUARIOS;
export const ASSEMBLEIA = process.env.REACT_APP_FEATURE_ASSEMBLEIA;
export const PARTICIPANTE = process.env.REACT_APP_FEATURE_PARTICIPANTE;
export const ACESSO_PORTAL = process.env.REACT_APP_FEATURE_ACESSO_PORTAL;
export const FUNDO = process.env.REACT_APP_FEATURE_FUNDO;
export const SUBCLASSE = process.env.REACT_APP_FEATURE_SUBCLASSE;
export const CARTEIRA = process.env.REACT_APP_FEATURE_CARTEIRA;
export const HISTORICO_CARTEIRA = process.env.REACT_APP_FEATURE_HISTORICO_CARTEIRA;
export const HISTORICO_COTAS = process.env.REACT_APP_FEATURE_HISTORICO_COTAS;
export const REMESSA = process.env.REACT_APP_FEATURE_REMESSA;
export const EXPORTACAO = process.env.REACT_APP_FEATURE_EXPORTAR;
export const CONTAS_RECEBER = process.env.REACT_APP_FEATURE_CONTAS_RECEBER;
export const EXPORTACAO_CONTAS_RECEBER = process.env.REACT_APP_FEATURE_EXPORTAR_CONTAS_RECEBER;
